:root {
  --bckgrnd-color: #f2f2f2;
  --lghtrblue: #e7f3fd;
  --lghtblue: #87C1f5;
  --midblue: #0090dc;
  --dkblue: #0061ab;
  --purple: #ab74c3;
  --white: #fff;
  --text: #000230;
}

html, body {
  height: 100%;
  margin: 0;
  font-family: 'Acumin Pro', sans-serif;
  color: var(--text);
}

/* Element Selectors */

h1 {
  font-size: 40px;
  font-weight: bold;
}

h2 {
  font-size: 50px;
  color: var(--midblue);
  margin-bottom: 40px;
}

h3 {
  color: var(--dkblue);
  font-weight: bolder;
}

h6 {
  color: var(--dkblue);
  font-weight: bold;
}

footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid var(--midblue);
  padding: 0 8px 0 8px;
  font-size: 12px;
  background-color: var(--white);
}

footer > a {
  transition: 0.3s ease all;
}

footer > a:hover {
  opacity: 0.7;
  transition: 0.3s ease all;
}

/* Class Selectors */

.homepage, .about-page, .pages {
  max-width: 1500px;
  margin: 0 auto;
}

.about-page, .pages {
  background-color: var(--white);
}

.homepage {
  padding: 45px 0px 10vh 0px;
}

.about-page {
  padding: 75px 0px 10vh 0px;
}

.pages {
  padding: 75px 5vw 10vh 5vw;
}

/* Class Selectors For Home Page */
.hero {
  position: relative;
  height: 339px;
  padding: 0 20px;
}

.hero-content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  background-color: rgba(0, 97, 171, 0.7);
  color: #fff;
  padding: 1vh 5vw;
}

.content {
  margin: 20px 5vw 25px 5vw;
  text-align: justify;
}

.intro-content {
  display: flex;
  flex-direction: column;
}

.intro-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.box {
  margin: 5px;
  padding: 30px;
}

.box-1 {
  background-color: var(--bckgrnd-color);
  box-shadow: 0px 10px 10px rgb(171, 116, 195, 0.2);
  filter: drop-shadow(0px 4px 4px rgb(0, 144, 220, 0.25));
  margin-bottom: 30px;
}

.box-2 {
  background: #87C1f5 url(./assets/images/mining.jpg) no-repeat 50% 60%;
  background-size: cover;
  height: 300px;
  margin: 5px;
  padding: 10px;
  margin-bottom: 30px;
}

.video {
  margin: 10px 5px 30px 5px;
}

.tracking-content {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.tracking-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px 0 30px 0;
  padding: 30px;
  box-shadow: 0px 10px 10px rgb(171, 116, 195, 0.2);
  filter: drop-shadow(0px 4px 4px rgb(0, 144, 220, 0.25));
  background-color: var(--bckgrnd-color);
}

.tracking-text h3 {
  margin: 10px 0px;
}

/* Class Selectors For About Page */

.about-page h2 {
  margin-left: 5vw;
}

.about-features {
  padding: 30px;
  box-shadow: 0px 10px 10px rgb(171, 116, 195, 0.2);
  filter: drop-shadow(0px 4px 4px rgb(0, 144, 220, 0.25));
  background-color: var(--bckgrnd-color);
  margin: 0px 5vw 40px 5vw;
}

.cover-image {
  position: relative;
  height: 300px;
  margin-bottom: 40px;
}

.about-safety {
  padding: 30px;
  box-shadow: 0px 10px 10px rgb(171, 116, 195, 0.2);
  filter: drop-shadow(0px 4px 4px rgb(0, 144, 220, 0.25));
  background-color: var(--bckgrnd-color);
  margin: 0px 5vw 40px 5vw;
}

.about-features h3, .about-safety h3 {
  margin-bottom: 20px;
}

.about-features ul, .about-safety ul {
  list-style-image: url(./assets/images/beam.png);
}

.about-desktop {
  display: none;
}

/* Class Selectors For Coverage Page */

.tailored, .tier {
  display: flex;
  margin: 5px 10px 40px 10px;
  flex-direction: column;
  box-shadow: 0px 10px 10px rgb(171, 116, 195, 0.2);
  filter: drop-shadow(0px 4px 4px rgb(0, 144, 220, 0.25));
  background-color: var(--bckgrnd-color);
}

.tailored-text, .tier-text {
  width: 100%;
  margin: 5px;
}

.tailored-text, .tier-text {
  padding: 30px 30px 0px 30px;
}

.tailored-image, .tier-image {
  background-color: #fff;
}

.coverage-videos {
  display: flex;
  flex-direction: column;
}

.video-container {
  position: relative;
  padding-top: 25;
  padding-bottom: 56.25%;
  width: 100%;
  height: 0;
  overflow: hidden;
}

.cvideo1 {
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cvideo2 {
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Class Selectors For More Page */

.more-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  padding: 30px;
  box-shadow: 0px 10px 10px rgb(171, 116, 195, 0.2);
  filter: drop-shadow(0px 4px 4px rgb(0, 144, 220, 0.25));
  background-color: var(--bckgrnd-color);
}

.more-brochure {
  margin-bottom: 40px;
}

.more-brochure h3, .more-contact h3, .more-turnkey h3 {
  margin-bottom: 20px;
}

.more-turnkey {
  padding: 30px;
  box-shadow: 0px 10px 10px rgb(171, 116, 195, 0.2);
  filter: drop-shadow(0px 4px 4px rgb(0, 144, 220, 0.25));
  background-color: var(--bckgrnd-color);
}

/* ID Selectors */

#navbar {
  background-color: var(--white);
  padding: 2px 8px;
  width: 100%;
  position: fixed;
  z-index: 3;
  filter: drop-shadow(0px 4px 4px rgb(0, 2, 48, 0.25));
}

#trackuracy-main {
  height: 30px;
}

#trackuracy {
  width: 60%;
}

#video {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

#rtls {
  width: 100%;
  margin: 5px;
  padding: 10px;
}

#cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

#brochure {
  max-width: 270px;
  padding: 10px;
  margin: 10px;
  box-shadow: 0px 0px 15px var(--text);
}

#kenwood-white {
  max-height: 150px;
  margin-top: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 10px 10px rgb(0, 0, 0, 0.2);
  filter: drop-shadow(0px 4px 4px rgb(0, 0, 0, 0.25));
}

#kenwood-white:hover {
  opacity: 0.7;
  transition: opacity .5s ease;
}

#email {
  position:relative;
  text-decoration: none;
  color: var(--text);
}

#email:hover {
  color: var(--midblue);
}

#email::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--midblue);
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease;
}

#email:hover::before {
  transform: scaleX(1);
}

#kenwoodMain, #facebook, #instagram, #linkedin, #twitter, #youtube {
  margin: 5px;
  height: 30px;
}

/* Media Queries */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  
  /* Home Page */
  #trackuracy {
    width: 30%;
  }

  /* About Page */
  .cover-image { 
    height: 500px;
  }

  /* Coverage Page */
  .tailored, .tier {
    flex-direction: row;
  }

  .tailored-text, .tailored-image, .tier-text, .tier-image {
    width: 50%;
  }

  .tailored-text, .tier-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tailored-text h3, .tier-text h3 {
    margin-bottom: 20px;
  }

  .coverage-videos {
    display: flex;
    flex-direction: row;
  }
  
  .video-container {
    padding-bottom: 28.125%;
  }

  /* ID Selectors */

  /* More Page */
  #brochure {
    max-width: 300px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* Class Selectors */

  /* Home Page */
  .intro-content {
    flex-direction: row;
  }

  .intro-text {
    width: 50%;
  }

  .box-2 {
    height: auto;
    width: 50%;
  }

  .tracking-content {
    flex-direction: row;
  }

  .tracking-text {
    width: 50%;
    margin-left: 10px; /* Will have to switch back to margin-right when videos come back */
    order: 2;
  }

  /* About Page */

  .about-features, .cover-image, .about-safety {
    display: none;
  }

  .about-desktop {
    display: flex;
    margin: 0px 5vw 0px 5vw;
  }

  .about-full-text {
    display: block;
    width: 50%;
    padding: 30px;
    box-shadow: 0px 10px 10px rgb(171, 116, 195, 0.2);
    filter: drop-shadow(0px 4px 4px rgb(0, 144, 220, 0.25));
    background-color: var(--bckgrnd-color);
  }

  .about-full-text h3 {
    margin-bottom: 20px;
  }
  .tex-color{
	  color:red;
	}
  .covervideo{
	top: 0;
	left: 0;
	width: 35%;
	height: 240px;
	border-radius: 10px;
  }

  .about-full-text ul {
    list-style-image: url(./assets/images/beam.png);
  }

  .about-full-text ul:first-of-type {
    margin-bottom: 40px;
  }

  .about-image {
    width: 50%;
    margin: 5px;
    margin-left: 30px;
  }

  /* Coverage Page */
  .tailored-text p, .tier-text p {
    font-size: 24px;
    line-height: 50px;
  }

  /* More Page */
  .more-info {
    flex-direction: row;
  }

  .more-brochure, .more-contact {
    width: 50%;
  }

  .more-contact {
    display: flex;
    flex-direction: column;
  }

  .more-contact h3 {
    margin-bottom: 55px;
  }
  
  .more-contact a, .more-contact address {
    align-self: center;
    font-size: 20px;
    line-height: 30px;
  }

  /* ID Selectors */

  /* Home Page */
  #trackuracy {
    width: 20%;
  }

  #rtls {
    width: 50%;
    margin: 5px 5px 30px 10px;
    align-self: center;
    order: 1;
  }

  /* About Page */
  
  #collage {
    object-fit: cover;
    height: 100%;
    width: 100%;
    align-self: center;
  }

  /* More Page */
  #brochure {
    box-shadow: none;
  }

  #brochure:hover {
    box-shadow: 0px 0px 15px var(--text);
  }
}